import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Note } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/note.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Note: Note }}
        code={snippet}
        platform="react-native"
        gitHubLink="notifications/note"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['oneOf', 'string']}>
            <Text>Determines the type of note.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="size" types={['oneOf', 'string']}>
            <Text>Determines the size of note.</Text>
            <List type="unordered">
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code>
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="children" types={['node']}>
            <Text>The text that appears in the note.</Text>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>Determines the theme of the note.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this note in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the note.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
